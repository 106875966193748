<template>
	<div class="homeQrCode ">
		<div v-for="(el,i) in list" :key="i" style="width: 800px;margin: 0 auto;">
			<div style="margin-bottom: 10px;">
			  {{el.type}} 
			  <el-button size="mini" type="primary" style="margin-left: 10px;" 
			  @click="downlode(el,i)">
				  下载
			  </el-button>
			</div>
			<div  class="flex" style="margin-bottom: 30px;">
				<div :id="item.id" class="item" style="position: relative;margin-right: 50px;" v-for="(item,ind) in el.child" :key="ind">
					<div style="text-align: center">
					    <img :src="el.imgUrl" alt="" width="300px" height="380px" style="border: 1px solid #e9e9e9;border-radius: 4px">
					    <div style="position: absolute;top: 100px;text-align: center;width: 300px;">
					        <h2 style="margin-bottom: 5px;">点击长按识别或微信扫一扫</h2>
					        <h2>{{item.type}}</h2>
							<br>
							<div :id="item.ref"  
								style="width: 150px;height: 150px;margin-left: 80px;">
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import QRCode from 'qrcode2'
	import html2canvas from 'html2canvas'
	export default {
		data(){
			return {
				list:[
					{
						type:'模板一',
						imgUrl:require('@/assets/img/codeBg1.jpg'),
						child:[
							{
								type:'会员登录',
								ref:'qrcode1',
								id:'member1'
							},
							{
								type:'员工登录',
								ref:'qrcode2',
								id:'staff1'
							}
						]
					},
					{
						type:'模板二',
						imgUrl:require('@/assets/img/codeBg2.jpg'),
						child:[
							{
								type:'会员登录',
								ref:'qrcode3',
								id:'member2'
							},
							{
								type:'员工登录',
								ref:'qrcode4',
								id:'staff2'
							}
						]
					}
				],
			}
		},
		mounted(){
		    document.title='移动端二维码'
			let cid = sessionStorage.getItem('cid')
			let staffUrl = 'https://www.xucheng.top/cph5/#/?cid=' + cid
			let memberUrl = 'https://www.xucheng.top/h5/#/?cid=' + cid
			let qrcode1 = new QRCode('qrcode1',{
			  text:memberUrl
			})
			let qrcode2 = new QRCode('qrcode2',{
			  text:staffUrl
			})
			let qrcode3 = new QRCode('qrcode3',{
			  text:memberUrl
			})
			let qrcode4 = new QRCode('qrcode4',{
			  text:staffUrl
			})
		},
		methods:{
			downlode(el,i){
				if(i==0){
					this.toImage('member1','会员端')
					this.toImage('staff1','员工端')
				}else{
					this.toImage('member2','会员端')
					this.toImage('staff2','员工端')
				}
			},
			toImage(imageTofile,name) {
			            // 手动创建一个 canvas 标签
			            const canvas = document.createElement("canvas")
			            // 获取父标签，意思是这个标签内的 DOM 元素生成图片
			            // imageTofile是给截图范围内的父级元素自定义的ref名称
			           
						// let canvasBox = this.$refs.imageTofile
						let canvasBox = document.getElementById(imageTofile)
						console.log(canvasBox,3333)
			            // 获取父级的宽高
			            // const width = parseInt(window.getComputedStyle(canvasBox).width)
			            // const height = parseInt(window.getComputedStyle(canvasBox).height)
			            
						const width = 300
						const height = 380
						
						
						// 宽高 * 2 并放大 2 倍 是为了防止图片模糊
			            canvas.width = width * 2
			            canvas.height = height * 2
			            canvas.style.width = width + 'px'
			            canvas.style.height = height + 'px'
			            const context = canvas.getContext("2d");
			            context.scale(2, 2);
			            const options = {
			                backgroundColor: null,
			                canvas: canvas,
			                useCORS: true
			            }
						
			            html2canvas(canvasBox, options).then((canvas) => {
			                // toDataURL 图片格式转成 base64
			                let dataURL = canvas.toDataURL("image/png")
			                this.downloadImage(dataURL,name)
			            })
			        },
			        //下载图片
			        downloadImage(url,name) {
			            // 如果是在网页中可以直接创建一个 a 标签直接下载
			            let a = document.createElement('a')
			            a.href = url
			            a.download = name
			            a.click()
			        },
		}
	}
</script>

<style lang="scss" scoped>
	.homeQrCode{
		
	}
</style>